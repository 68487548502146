/* eslint-disable consistent-return */
import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Top from '../../../components/Top';

import moment from 'moment';
import 'moment/locale/ru';


import {lay} from '../../../constants/Layout';
import { Height } from '@material-ui/icons';

import {Context} from '../../../functions/context';
import OrderModal from './OrderModal';

import axi from '../../../functions/axiosf';
import WaitScreen from '../../WaitScreen';


export default function DefaultOrders(props) {

  const context = useContext(Context);

  const classes = useStyles();
  const [selectedDiet, setDiet] = useState(null);
  const [edited, setEdited] = useState(0);
  const [orders, setOrders] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);



  const handleSelectDiet = async (diet) => {
    setIsWaiting(true);
    setDiet(diet);
    //тут запрашиваем на сервере набор заказов по умолчанию для данной диеты

    let file = 'readDefaultWorkpieces.php';

    //переаписать с промиса на трай ассинк
    axi(file, '', { token: context.token, diet: diet?.id }).then((result) => {
      if (result.type === 'approved') {
        if (typeof result.defaultWorkpieces === 'object') {
          setOrders(result.defaultWorkpieces);   
        } 
        setIsWaiting(false);
      } else {
        alert('сбой авторизации, войдите снова');
        setIsWaiting(false);
      }
    }, (e) => {
      alert(e);
      setIsWaiting(false);
    });
    
  };

  return (
    
    <>
      <Top/>
      
      {selectedDiet && orders &&
        <OrderModal
          onSetDiet={setDiet}
          onSetOrders={setOrders}
          diet={selectedDiet}
          orders={orders}
        />
      }

      <Container className={classes.container} >
        {context.diets.map((item, index) => 
          <DietDiv
            key={index}
            onClick={()=>{
              handleSelectDiet(item);
            }}
            style={{
              backgroundColor: (context.theme==='light'&& typeof index === 'number') ? '#dde9':'#4459',
            }}
          >
            {item.name!==''?
              <b className={classes.roomLabelRow}>
                {'диета: '+item.name}
              </b>
              :
              <></>
            }
          </DietDiv>
        )}
      </Container>

      {isWaiting&&
        <WaitScreen/>
      }
    </>
  );
}


const useStyles = makeStyles((theme) => ({
  container: {
    flexWrap: 'wrap',
    flexDirection:  'row',
    alignItems: 'flex-start',
    justifyContent:'flex-start',
    paddingTop:  80,
    height: lay.window.height-80,
    transition: '1s',        
    backgroundColor: theme.palette.backgroundColor,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  },
  roomLabelRow:{ 
    color: theme.palette.text.primary,
    textAlign: 'center',
    padding: 5
  }  
}));

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;

const DietDiv = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
  border: 2px solid #778;
  border-radius: 8px;
  margin: 4px;
  cursor: pointer;
  width: 160px;
  height: 80px;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: '1s'
`;
