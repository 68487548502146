/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect, Fragment } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment/locale/ru';
import weekdays from '../../../../constants/weekdays';

import {lay} from '../../../../constants/Layout';
import { Height } from '@material-ui/icons';
import {Context} from '../../../../functions/context';
import { TextField } from '@material-ui/core';
import Paragraph from '../../../../styledComponents/Paragraph';

const isoWeekDay = moment(+new Date()).isoWeekday();

export default function MealTimeItem(props) {

  const classes = useStyles();
  const context = useContext(Context);
  let { itemTime, 
    indexTime,
    today,
  } = props;

  const [mealsButtons, setMealsButtons] = useState([]);
  useEffect(()=>{

  },[props.currentWeekDay]);

  const handleOnPressMenuItem = (itemMeal, itemTimeId, itemTypeId, checked) => {
    if(typeof props.handleOnPressMenuItem === 'function'){
      props.handleOnPressMenuItem(itemMeal, itemTimeId, itemTypeId, checked);
    }
  };

  return(
    <Container
      style={{
        flexWrap: 'nowrap',
        width: lay.window.width,
        overflowY: 'hidden',
      }}
    >
      <h3
        style={{
          color: (context.theme==='light') ? '#224':'#bbf',
        }}>
        {weekdays.filter((dayValue)=> +dayValue.id === +props.currentWeekDay)[0].name}
        {': '+itemTime.name+' начало готовки в '+ itemTime.timeToCook}
      </h3>
      {context.mealsTypes.map((itemType, indexType)=>{
        
        let mealsButtons = [];
        context.meals.map((itemMealFromAll, indexMealFromAll)=>{
          if ((itemMealFromAll.time.indexOf(+itemTime.id)>-1)  
              &&(itemMealFromAll.type === itemType.id) 
              &&(itemMealFromAll.diet.indexOf(+props.diet.id)>-1)
              &&(itemMealFromAll.weekday.indexOf(+props.currentWeekDay)>-1)
          ){
            mealsButtons = [...mealsButtons, itemMealFromAll];
          }
        });
        return(
          <>
            {mealsButtons.length > 0 &&
                  <Container
                    key={'type'+indexType}
                    style={{
                      flexWrap: 'nowrap',
                      alignItems: 'center',
                      width: lay.window.width,
                      overflowY: 'hidden',
                    }}
                  >
                    <span
                      style={{
                        color: (context.theme==='light') ? '#222':'#eef',
                      }}>
                      {itemType.name}
                    </span>
                    <ButtonGroup 
                      className={classes.margin16}             
                      size="medium" color="primary" 
                      orientation="vertical"
                      aria-label="vertical medium primary button group">

                      {mealsButtons.map((itemMeal, indexMeal)=>{
                        let checked = false;
                        props.orders.map((orderCheckItem, orderCheckIndex)=>{
                          if ((orderCheckItem.mealTime === itemTime.id)&&
                                (orderCheckItem.mealType === itemType.id)){
                            if(orderCheckItem.meal === itemMeal.id){
                              checked = true;
                            }
                          }
                        });
                        return(
                          <Button 
                            key={'meal'+indexMeal}
                            className={classes.button}
                            variant={checked?'contained':'outlined'}
                            onClick={()=>{
                              handleOnPressMenuItem(itemMeal, itemTime.id, itemType.id, checked);
                            }}
                            disabled={props.isWaiting}
                          >{itemMeal.name}</Button>
                        );
                      })
                      }
                    </ButtonGroup>
                    
                  </Container>
            }
          </>
        );
      })}
    </Container>);
      
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  }
  
}));

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;

const MicroP = styled.p`
  font-size: 10px;
  margin-bottom: 16px;
  margin-top: -16px;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 220;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const localStyles = {
  calcResult: {
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    width: 200,    
    borderBottomColor: '#404040',
    borderWidth: 1,
    borderBottomStyle: 'dotted',
  }
};