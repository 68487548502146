import React from 'react';
//import { BrowserRouter as Router, Route } from "react-router-dom";

import styled, { css } from 'styled-components';
import './icons.js';
import Main from './screens/Main';
import Statistic from './screens/Statistic';
import Print from './screens/Print';
import LogIn from './screens/LogIn';
import Header from './components/Header';
import Diets from './screens/Admin/Diets';
import PlateGroups from './screens/Admin/PlateGroups.js'; 
import Meals from './screens/Admin/Meals';
import MealsTypes from './screens/Admin/MealsTypes';
import MealTimes from './screens/Admin/MealTimes';
import Rooms from './screens/Admin/Rooms';
import Accounts from './screens/Admin/Accounts';
import Log from './screens/Admin/Log';
import './style.css';
import axi from './functions/axiosf';
//import 'react-activity/dist/react-activity.css';

import {Context} from './functions/context';

import {ThemeProvider} from '@material-ui/core/styles';
import { light, dark } from './constants/muiThemes.js';

import moment from 'moment';
import 'moment/locale/ru';
import zIndex from '@material-ui/core/styles/zIndex';
import WaitScreen from './screens/WaitScreen.js';
import DefaultOrders from './screens/Admin/DefaultOrders/index.js';
const today = moment(+new Date()).format('ll');
const dayOfWeek = moment().isoWeekday();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: 'LogIn',
      route: this.route(),
      token: '',
      userRole: '',
      menuOpenStatus: 0,
      menuOpenStatusToggle: this.menuOpenStatusToggle,
      sourceToPrint: [], 
      selected: '',
      theme: 'light',
      toggleTheme: this.toggleTheme,
      rooms: [],
      setRooms: this.setRooms,
      diets:[{name: 'постная'},{name: 'белковая'}],
      setDiets: this.setDiets,        
      mealsTypes:[{name: 'первое'},{name: 'второе'}],
      setMealsTypes: this.setMealsTypes,
      mealTimes:[{name: 'завтрак'},{name: 'обед'}],
      setMealTimes: this.setMealTimes,
      plateGroup: [],
      setPlateGroup: this.setPlateGroup,
      meals: [],
      mealsNumb: {},
      onSave: this.onSave,
      axiMealsForm: this.axiMealsForm,
      axiDietRoom: this.axiDietRoom,
      orders: [],
      axiSaveOrders: this.axiSaveOrders,
      additionalMealsComments: [],
      cookList: {},
      cookOutPrint: {},
      cookRoomsPrint: {},
      axiUpdate: this.axiUpdate,
      updateDay: today,
      dayOfWeek: dayOfWeek,
      setUpdateDay: this.setUpdateDay,
      accounts: [],
      roles: ['администратор', 'диетолог', 'буфетчица', 'повар', ],
      log: [],
    };

  }

  componentDidMount(){
    setInterval(() => {
      if (this.state.userRole!=='буфетчица'){
        this.axiUpdate();
      } 
    }, 120*1000);   
  }

  setUpdateDay = (d) => {
    this.setState({updateDay: d});
  };

  route=(u)=>{
    this.setState({screen: u});
  };

  menuOpenStatusToggle=(u)=>{
    this.setState({ menuOpenStatus: u });
  };

  addToken=(u, s)=>{
    this.setState({ token: u });
    this.axiUpdate(s, u, this.state.updateDay, this.state.dayOfWeek);
  };

  toggleTheme=()=>{
    let t = (this.state.theme==='light')?'dark':'light';
    this.setState({theme: t});
  };

  setRooms = (roomsArray) => {
    this.setState({rooms: roomsArray});
  };

  setDiets = (array) => {
    this.setState({diets: array});
  };

  setMealsTypes = (array) => {
    this.setState({mealsTypes: array});
  };

  setMealTimes = (array) => {
    this.setState({mealTimes: array});
  };

  setPlateGroup = (array) => {
    this.setState({plateGroup: array});
  };

  axiMealsForm = (method, data)=>{
    this.setState({ modalWait: true});
    axi('mealsForm.php', method, { token: this.state.token, data: data }).then((result) => {
      if (result.type === 'approved') {
        this.contextUpdate(result);   
        this.setState({modalWait: false});    
      } else {
        this.setState({ screen: 'LogIn', token: '' });
        this.setState({modalWait: false});
      }
    }, (e) => {
    });
  };

  
  axiDietRoom = (method, data)=>{
    this.setState({ modalWait: true});

    axi('dietsInRoom.php', method, { token: this.state.token, data: data }).then((result) => {
      if (result.type === 'approved') {
        //this.contextUpdate(result);   
        this.axiUpdate();
        
        return true; //нужно будет следить за тестированием продукта и возможными багами или крашами 
      } else {
        this.setState({ screen: 'LogIn', token: '' });
        return false; //нужно будет следить за тестированием продукта и возможными багами или крашами 
      }
    }, (e) => { 
    });
    this.setState({modalWait: false});
  };

  axiSaveOrders = (data)=>{
    this.setState({ modalWait: true});

    axi('barOrders.php', 'save', { 
      token: this.state.token, 
      data: data,
      dayOfWeek: this.state.dayOfWeek }).then((result) => {
      if (result.type === 'approved') {
        this.contextUpdate(result);        
      } else {
        this.setState({ screen: 'LogIn', token: '' });
      }
    }, (e) => { 
    });
    this.setState({modalWait: false});
  };

  axiUpdate = async (s=this.state.screen, tok=this.state.token, day=this.state.updateDay, dayOfWeek=this.state.dayOfWeek)=>{
    this.setState({ modalWait: true, dayOfWeek: dayOfWeek});

    axi('login.php', null, { 'token': tok, 'today': day, 'dayOfWeek': dayOfWeek}).then((result) => {
      if (result.type === 'approved') {
        this.contextUpdate(result);   
        this.setState({screen: s, modalWait: false});  
        return true; //нужно будет следить за тестированием продукта и возможными багами или крашами   
      } else {
        this.setState({ screen: 'LogIn', token: '', modalWait: false });
        return false; //нужно будет следить за тестированием продукта и возможными багами или крашами  
      }
    }, (e) => { 
    });
  };

  onSave=(file, method, data)=>{
    this.setState({ modalWait: true});

    axi(file, method, { token: this.state.token, data: data }).then((result) => {
      if (result.type === 'approved') {
        this.contextUpdate(result);        
      } else {
        this.setState({ screen: 'LogIn', token: '' });
      }
    }, (e) => {
    });
    this.setState({modalWait: false});
  };


  contextUpdate=(result)=>{
    this.setState({
      meals: result.meals,
    });
    if (result.additionalMealsComments){
      this.setState({
        additionalMealsComments: result.additionalMealsComments,
      });
    }
    let mealsNumb={};
    result.meals.map((itemMeal, indexf) => {
      //let full = {id: itemMeal.id, name: itemMeal.name, time: {}, type: {}, diet: {} }
      let full = {...itemMeal};
      full.time=[];
      full.diet=[];
      result.mealTimes.map((itemTime, indexd)=>{
        if(itemMeal.time.indexOf(itemTime.id) !== -1){
          full.time=[...full.time, itemTime];

           
          result.mealsTypes.map((itemType, indexType) => {
            if(itemType.id===itemMeal.type){
              full.type=itemType;
            }
          });
          result.diets.map((itemDiet, indexDiet) => {
            if(itemMeal.diet.indexOf(itemDiet.id) !== -1){ //itemd.id===itemMeal.diet
              full.diet=[...full.diet, itemDiet];
            }
          }); 
        }
      });
      mealsNumb[itemMeal.id]={...itemMeal, ...full};
    }); 

    let roomsFull=[];

    result.rooms.map((itemRoom, indexf) => {
      let full = itemRoom;
      if(itemRoom.diet==='0'){
        full.diet={id: 0, name: null};
      }else{
        result.diets.map((itemd, indexd)=>{
          if(itemd.id===itemRoom.diet){
            full.diet=itemd;
          }
        });
      }
      roomsFull=[...roomsFull, full];
    });
    //console.log(roomsFull)


    this.setState({ 
      diets: result.diets, 
      mealsTypes: result.mealsTypes, 
      mealTimes: result.mealTimes,
      mealsNumb: mealsNumb,
      rooms: roomsFull,
      log: result.log,
    });
    if(result.orders){
      let newRooms = roomsFull;
      let cookList = {};
      let cookOutPrint = {};
      let newCookRoomsPrint = {};
      let groupMealName = '';
      result.orders.map((itemOrder, indexf) => {
        let mealTimeName = '';
        let mealTimeId = 0;
        let mealTypeName = '';
        let mealTypeId = 0;
        let plateGroupArray = [];
        let plateGroupFoodName = '';
        result.mealTimes.map((itemTime, indexf) => {
          if(+itemTime.id === +itemOrder.mealTime){
            mealTimeName = itemTime.name;
            mealTimeId = +itemTime.id;
          } 
        });
        let flagIsPlateGroup = {};
        if(result.plateGroup){
          result.mealsTypes.map((itemType, indexf) => {
            if(+itemType.id === +itemOrder.mealType){
              mealTypeName = itemType.name;
              mealTypeId = +itemType.id;
              if ( typeof itemType.plateGroup === 'number' ||   typeof itemType.plateGroup === 'string' ){
                flagIsPlateGroup = result.plateGroup.find((el) => +el.id === +itemType.plateGroup);
              }
            } 
          });
          if(flagIsPlateGroup && typeof flagIsPlateGroup.name === 'string' ){
            result.mealsTypes.map((itemType, indexf) => {
              // eslint-disable-next-line eqeqeq
              if(itemType.plateGroup == flagIsPlateGroup.id){
                plateGroupArray = [...plateGroupArray, itemType];
                plateGroupFoodName = plateGroupFoodName + itemType.name + ' ';        
              } 
          
            });
          }  
        }
        newRooms.map((itemRoom, indexRoom)=>{
          if (itemRoom.number === itemOrder.roomName) {
            if (!newRooms[indexRoom][mealTimeName]){
              newRooms[indexRoom][mealTimeName]={};
            }                 
            if(itemRoom.name===itemOrder.name){
              if (+itemOrder.meal>0){

                //первый уровень вложенности
                if(!cookList[mealTimeName]){
                  cookList[mealTimeName]={};
                }
                if(!cookOutPrint[mealTimeName]){
                  cookOutPrint[mealTimeName]={};
                }
                if(!newCookRoomsPrint[itemRoom.number]){
                  newCookRoomsPrint[itemRoom.number]={};
                }

                //второй уровень
                if(!cookList[mealTimeName][mealTypeName]){
                  cookList[mealTimeName][mealTypeName]={};
                }


                //тут нужно проверить, кладётся ли это блюдо на тарелку с каким нить другим блюдом
                //если  есть  id группы, то пишем вместо mealTypeName name этой группы

                if(flagIsPlateGroup && flagIsPlateGroup.name){
                  
                  if(!cookOutPrint[mealTimeName]['plateGroup']){ 
                    cookOutPrint[mealTimeName]['plateGroup'] = {};
                  }
                  if(!cookOutPrint[mealTimeName]['plateGroup'][flagIsPlateGroup.name]){ 
                    cookOutPrint[mealTimeName]['plateGroup'][flagIsPlateGroup.name] = {};
                  }
                  //3
                  //тут смотрим 
                  // вместо id блюда давайте попробуем просто ставить составное имя и сразу этот ключ использовать в render
                  // ещё обязательно проверять есть ли уже эта палата на этом ключе, потому что мы же по два (и более) раза проходить будем всё
                  groupMealName = '';
                  plateGroupArray.map((itemPlateGroupArray) => {
                    //подбираем название непосредственно самого блюда
                    
                    result.orders.map((otherOrder) => {
                      if(+otherOrder.mealTime === +itemOrder.mealTime && +otherOrder.mealType === +itemPlateGroupArray.id && otherOrder.roomName === itemRoom.number ){
                        console.log('есть вот такие блюда', mealsNumb[otherOrder.meal]);
                        groupMealName = groupMealName + mealsNumb[otherOrder.meal].name + ' | ';        //нам нужно выковырять название блюда из ордеров
                      } 
                    
                    });
                  });
                  let isExistRoom = false;
                  if(!cookOutPrint[mealTimeName]['plateGroup'][flagIsPlateGroup.name][groupMealName]){
                    cookOutPrint[mealTimeName]['plateGroup'][flagIsPlateGroup.name][groupMealName]=[itemRoom.number];
                  }else{
                    isExistRoom = cookOutPrint[mealTimeName]['plateGroup'][flagIsPlateGroup.name][groupMealName].find((el) => el === itemRoom.number);
                    if (!isExistRoom){
                      cookOutPrint[mealTimeName]['plateGroup'][flagIsPlateGroup.name][groupMealName]=[...cookOutPrint[mealTimeName]['plateGroup'][flagIsPlateGroup.name][groupMealName], itemRoom.number];
                    }
                  }

                }else{   

                  if(!cookOutPrint[mealTimeName][mealTypeName]){ 
                    cookOutPrint[mealTimeName][mealTypeName]={};
                  }

                  //3
                  if(!cookOutPrint[mealTimeName][mealTypeName][itemOrder.meal]){
                    cookOutPrint[mealTimeName][mealTypeName][itemOrder.meal]=[itemRoom.number];
                  }else{
                    cookOutPrint[mealTimeName][mealTypeName][itemOrder.meal]=[...cookOutPrint[mealTimeName][mealTypeName][itemOrder.meal], itemRoom.number];
                  }
                  
                }

                
                if(!newCookRoomsPrint[itemRoom.number][mealTimeName]){
                  newCookRoomsPrint[itemRoom.number][mealTimeName]={};
                }

                //третий уровень вложенности
                if(!cookList[mealTimeName][mealTypeName][itemOrder.meal]){////////////
                  cookList[mealTimeName][mealTypeName][itemOrder.meal]=1;
                }else{
                  cookList[mealTimeName][mealTypeName][itemOrder.meal]++;
                }

                if(!newCookRoomsPrint[itemRoom.number][mealTimeName][mealTypeName]){
                  newCookRoomsPrint[itemRoom.number][mealTimeName][mealTypeName]=[itemOrder.meal];
                }else{
                  newCookRoomsPrint[itemRoom.number][mealTimeName][mealTypeName]=[...newCookRoomsPrint[itemRoom.number][mealTimeName][mealTypeName], itemOrder.meal];
                }
              }
            }
          }
        });
      });
      this.setState({
        orders: result.orders,
        rooms: newRooms,
        cookList: cookList,
        cookOutPrint:cookOutPrint,
        cookRoomsPrint: newCookRoomsPrint,
      }
      );
    }else{
      this.setState({
        orders: [],
        cookList: {},
        cookOutPrint:{},
        cookRoomsPrint: {},
      });
    }
    if(result.accounts){
      this.setState({accounts: result.accounts});
    }
    if(result.plateGroup){
      this.setState({plateGroup: result.plateGroup});
    }
    if(result.userRole){
      this.setState({
        userRole: result.userRole
      });
    }
  };

  render(){

    return (
      
      <Context.Provider value={this.state}>
        <ThemeProvider theme={(this.state.theme==='light')?light:dark}>
          <Container>
            {
              this.state.screen === 'wait'||this.state.modalWait &&
              <WaitScreen/>
            }
            { this.state.screen === 'LogIn' &&
              <LogIn {...this.state} 
                //route={(u) => this.route(u)} 
                addToken={(u, s) => { 
                  this.addToken(u, s);
                } 
                } 
        
              />}
            {/*

            { this.state.screen == 'Statistic' && 
              <Statistic {...this.state} 
                update={(s) => { this.axiUpdate(s) }}
              /> }
           
 */}
            { this.state.screen === 'Main' && 
              <Main {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }}
              /> }
            { this.state.screen === 'diets' && 
              <Diets {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }}
              /> }
            { this.state.screen === 'plateGroups' && 
              <PlateGroups {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }}
              /> }
            { this.state.screen === 'rooms' && 
              <Rooms {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }}  

              />}
            { this.state.screen === 'mealtime' && 
              <MealTimes 
                {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }} 
              /> 
            }
            { this.state.screen === 'meals' && 
              <Meals {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }}  

              />}
            { this.state.screen === 'mealsTypes' && 
              <MealsTypes {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }}  

              />}
            { this.state.screen === 'accounts' && 
              <Accounts {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }}  
              />}
            { this.state.screen === 'history' && 
              <Log {...this.state} 
                update={(s) => {
                  this.axiUpdate(s); 
                }}  
              />}
            { this.state.screen === 'DefaultOrders' && 
              <DefaultOrders {...this.state} 
                update={this.axiUpdate}  
              />}
            { this.state.screen === 'Print' && 
              <Print {...this.state} route={(u) => {this.route(u)}} />}
            {this.state.screen !== 'LogIn' && this.state.screen !== 'Print' && this.state.userRole ==='администратор' &&
          
              <Header
                {...this.state} 
                route={(u) => {this.route(u)}}
                menuOpenStatusToggle={(u) => this.menuOpenStatusToggle(u)}
                toggleTable={()=>this.setState({selectedTable: !this.state.selectedTable})}
              ></Header>  
            }    
          </Container>
        </ThemeProvider>
      </Context.Provider>
    );
  }
}

const Container = styled.div`
  display: flex;
  float:none;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
`;
const HeaderComponentStack = styled.div`
  top: 0px;
  left: 0px;
  width: 160px;
  height: 0px;
  position: fixed;
  z-index: 250;
  overflow-y: visible;
`;
export default App;