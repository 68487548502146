/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import 'moment/locale/ru';
import weekdays from '../../../../constants/weekdays';

import {lay} from '../../../../constants/Layout';
import { Height } from '@material-ui/icons';
import {Context} from '../../../../functions/context';
import { TextField } from '@material-ui/core';
import Paragraph from '../../../../styledComponents/Paragraph';
import MealTimeItem from './MealTimeItem';
import axi from '../../../../functions/axiosf';

const isoWeekDay = moment(+new Date()).isoWeekday();

export default function OrderModal(props) {

  const context = useContext(Context);
  const classes = useStyles();

  const [currentWeekDay, setCurrentWeekDay] = useState(isoWeekDay);
  const [currentOrders, setCurrentOrders] = useState(props.orders);
  

  const [edited, setEdited] = useState(0);
  const [proteins, setProts] = useState(0);
  const [fats, setFats] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [calorics, setCals] = useState(0);

  const [isWaiting, setIsWaiting] = useState(false);

  const today = moment(+new Date()).format('ll');
  const nowTime = moment(+new Date()).format('kk:mm');
  let nowH = nowTime[0]+nowTime[1];
  const nowM = nowTime[3]+nowTime[4];
  if(nowH==='24'){
    nowH = '00';
  }
  const nowMinutes = +nowH*60 + +nowM;
  
  const handleOnPressMenuItem = async (mealObj, time, type, checked)=>{    
    setIsWaiting(true);
    let newOrder = {
      meal: mealObj.id,
      mealTime: time,
      mealType: type,
      currentWeekDay: currentWeekDay,
      diet: props.diet?.id,
      onDelete: checked,
      token: context.token
    };

    //тут пишем запрос на запись нового предустановленного заказа или на изменение старого 
    //новая это запись или старая мы проверяем на хостинге поиском по mealTime, mealType и diet

    let file = 'modifyDefaultWorkpieces.php';

    //переаписать с промиса на трай ассинк
    axi(file, '', newOrder).then((result) => {
      if (result.type === 'approved') {        
        if (typeof props.onSetOrders === 'function'){
          props.onSetOrders(result.defaultWorkpieces);
        } 
        setIsWaiting(false);
      } else {
        alert('сбой авторизации, войдите снова');
        setIsWaiting(false);
      }
    }, (e) => {
      alert(e);
      setIsWaiting(false);
    });
    
    calcCalorics();
    setEdited(1);
  };

  const calcCalorics=()=>{
    let newProteins = 0;
    let newFats = 0;
    let newCarbs = 0;
    let newCaloric = 0;
    props.orders.map((orderItem, orderIndex) => {
      context.meals.map((mealItem, mealIndex)=>{
        if (orderItem.meal === mealItem.id){
          newProteins += (+mealItem.proteins);
          newFats += (+mealItem.fats);
          newCarbs += (+mealItem.carbs);
          newCaloric += (+mealItem.caloric);
        }
      });
    });
    
    setProts(newProteins);
    setFats(newFats);
    setCarbs(newCarbs);
    setCals(newCaloric);
  };

  const handlePressCancelButton = ()=>{
    if (typeof props.onSetDiet === 'function'){
      props.onSetDiet(null);
      let cleenArr = [];
      if (typeof props.onSetOrders === 'function'){
        props.onSetOrders(cleenArr);
      }
    }
  };

  const filterOrders = () => {
    let filteredOrders = [];
    filteredOrders = props.orders.filter((itemValue)=>{
      return +itemValue.weekday === +currentWeekDay;
    });
    setCurrentOrders(filteredOrders);
  };

  const handleChooseWeekDay = (e)=>{
    setCurrentWeekDay(e.target.value);
  };
  
  useEffect(()=>{
    calcCalorics();
    filterOrders();
  },[props.orders, currentWeekDay]);

  return (
    <PopUp
      style={{
        backgroundColor: (context.theme==='light')?'#fff':'#000',
      }}
    > 
           
      <Container
        style={{
          backgroundColor: (context.theme==='light')?'#fff':'#000',
          marginTop: 16,
        }}
      >
        <Button 
          style={{margin: 16,}}
          color="secondary"
          variant="contained"
          onClick={handlePressCancelButton}>
              закрыть
        </Button>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">день недели</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={currentWeekDay}
            onChange={handleChooseWeekDay}
            label="день недели"
          >
            {weekdays.map((item, index)=>
              <MenuItem 
                key={index} 
                value={item.id}>
                {item.name}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        {context.mealTimes.map((itemTime, indexTime)=> {
          
          return (
            <MealTimeItem
              key={'time'+indexTime}
              itemTime = {itemTime}
              indexTime = {indexTime}
              today = {today}
              orders = {currentOrders}
              handleOnPressMenuItem = {handleOnPressMenuItem}
              selectedDiet = {props.selectedDiet}
              diet={props.diet}
              nowMinutes = {nowMinutes}
              isWaiting={isWaiting}
              currentWeekDay={currentWeekDay}
            />
          );
        }
        )}
        <div
          style={{
            display: 'flex',
            flexDirection:'column',
            backgroundColor: (context.theme==='light')?'#ccc':'#333',
            borderRadius: 8,
            padding: 8,
            margin: 8
          }}
        >
          <b
            style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>за весь день</b>
          <div
            style={{
              ...localStyles.calcResult,
              width: 300,
              backgroundColor: (Math.abs(props.diet.proteins-proteins))<10?'#99cc3399':(Math.abs(props.diet.proteins-proteins))>500?'#dd563399':'transparent'
            }}
          >
            <span
              style={{
                color: (context.theme==='light') ? '#222':'#eef',
              }}>Белки:</span>
            <span style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>{proteins+' из '+props.diet.proteins+' грамм'}</span>
          </div>
          <div
            style={{
              ...localStyles.calcResult,
              width: 300,
              backgroundColor: (Math.abs(props.diet.fats-fats))<10?'#99cc3399':(Math.abs(props.diet.fats-fats))>500?'#dd563399':'transparent'
                            
            }}
          >
            <span
              style={{
                color: (context.theme==='light') ? '#222':'#eef',
              }}>Жиры:</span>
            <span style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>{fats+' из '+props.diet.fats+' грамм'}</span>
          </div>
          <div
            style={{
              ...localStyles.calcResult,
              width: 300,
              backgroundColor: (Math.abs(props.diet.carbs-carbs))<10?'#99cc3399':(Math.abs(props.diet.carbs-carbs))>500?'#dd563399':'transparent'
                            
            }}
          >
            <span
              style={{
                color: (context.theme==='light') ? '#222':'#eef',
              }}>Углеводы:</span>
            <span style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>{carbs+' из '+props.diet.carbs+' грамм'}</span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection:'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 32,
              width: 300,
              backgroundColor: (Math.abs(props.diet.caloric-calorics))<50?'#99cc3399':(Math.abs(props.diet.caloric-calorics))>1000?'#dd563399':'transparent'
            }}
          >
            <span
              style={{
                color: (context.theme==='light') ? '#222':'#eef',
              }}>Калорийность:</span>
            <span style={{
              color: (context.theme==='light') ? '#222':'#eef',
            }}>{calorics+' из '+props.diet.caloric+' кКал'}</span>
          </div>
        </div>
     
          
        <Button 
          style={{margin: 4, marginBottom: 80}}
          color="secondary"
          variant="contained"
          onClick={handlePressCancelButton}>
            закрыть
        </Button>
      </Container>
    </PopUp>
     
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    transition: '1s'
  },
  margin16: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 260,
    transition: '1s',
    color: theme.palette.text.primary
  },
  button: {
    transition: '1s'
  }
  
}));

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: '1s'
`;

const PopUp = styled.div`
  ${'' /* display: flex; */}
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 200;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 220;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const localStyles = {
  calcResult: {
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    width: 200,    
    borderBottomColor: '#404040',
    borderWidth: 1,
    borderBottomStyle: 'dotted',
  }
};